import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { TaskId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { getCmdbRestBaseUrl } from './index.tsx';

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/task/${taskId}
 */
export const getCmdbTaskByIdUrl = (workspaceId: WorkspaceId, taskId: TaskId) =>
	`${getCmdbRestBaseUrl(workspaceId)}/task/${taskId}`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/task/${taskId}/archive
 */
export const getCmdbTaskArchiveUrl = (workspaceId: WorkspaceId, taskId: TaskId) =>
	`${getCmdbTaskByIdUrl(workspaceId, taskId)}/archive`;

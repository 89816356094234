import React, { type ReactNode } from 'react';
import WarningIcon from '@atlaskit/icon/core/warning';
import { Box, Inline, Stack, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { CompletedTask } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import messages from './messages.tsx';

export type ErrorMessageProps = {
	errorCount: number;
};

export const ErrorMessage = ({ errorCount }: ErrorMessageProps) => {
	const { formatMessage } = useIntl();
	return (
		<Inline
			space="space.100"
			alignBlock="center"
			testId="servicedesk-cmdb-async-actions.common.ui.flags.completed-flag-description.error-message"
		>
			<WarningIcon
				label={formatMessage(messages.warningIcon)}
				color={token('color.icon.warning')}
			/>
			<Text weight="medium" color="color.text.warning">
				{formatMessage(messages.errorsEncountered, { errorCount })}
			</Text>
		</Inline>
	);
};

type CompletedFlagDescriptionProps = {
	task: CompletedTask;
	children?: ReactNode;
};

export const CompletedFlagDescription = ({ task, children }: CompletedFlagDescriptionProps) => {
	return (
		<Stack space="space.100">
			{task.resultPayload.hasError && (
				<ErrorMessage errorCount={task.resultPayload.objectsEncounteredError} />
			)}
			{children && (
				<Box testId="servicedesk-cmdb-async-actions.common.ui.flags.completed-flag-description.children-wrapper">
					{children}
				</Box>
			)}
		</Stack>
	);
};

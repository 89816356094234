import React, { type ReactElement } from 'react';
import { AsyncCmdbUpsellPage } from '@atlassian/jira-cmdb-upsell-page/src/async.tsx';
import {
	GenericPermissionErrorPageAsync,
	NotFoundErrorPageAsync,
} from '@atlassian/jira-error-pages/src/async.tsx';
import { isCMDBEnabledInEnvironment } from '@atlassian/jira-servicedesk-cmdb-fedramp/src/index.tsx';
import {
	useInsightVisibility,
	useWorkspaceContext,
} from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/controllers/workspace-context/index.tsx';
import { isLicensedPremiumUser } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/get-data/index.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { PageStyle } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-style/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { CmdbArchitectureAnalyticsData } from '@atlassian/jira-servicedesk-insight-shared-utils/src/common/utils/get-cmdb-analytics/index.tsx';

type Props = {
	children: (arg1: { workspaceId: WorkspaceId }) => ReactElement;
};

export const InsightAppBaseV2 = ({ children }: Props) => {
	const { workspaceId, error, loading } = useWorkspaceContext();
	const hasInsightPermission = useInsightVisibility();
	const tenantContext = useTenantContext();

	if (!isCMDBEnabledInEnvironment()) {
		return <NotFoundErrorPageAsync />;
	}

	if (!isLicensedPremiumUser(tenantContext)) {
		return <AsyncCmdbUpsellPage />;
	}

	if (workspaceId == null) {
		return !loading && !error ? <NotFoundErrorPageAsync /> : null;
	}

	if (hasInsightPermission === false || error) {
		return <GenericPermissionErrorPageAsync />;
	}

	return (
		<PageStyle>
			<CmdbArchitectureAnalyticsData>{children({ workspaceId })}</CmdbArchitectureAnalyticsData>
		</PageStyle>
	);
};

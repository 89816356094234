import { POLLING_DELAY } from '../../../common/constants.tsx';
import { startTaskPolling } from '../../actions/start-task-polling/index.tsx';
import type { CmdbAsyncActionStoreAction } from '../../types.tsx';

export const fetchInitialTasks =
	(): CmdbAsyncActionStoreAction =>
	({ getState, setState, dispatch }) => {
		const timeoutId = setTimeout(() => {
			if (!getState().isPolling && !getState().tasks) {
				dispatch(startTaskPolling());
			}
		}, POLLING_DELAY);
		setState({ pollingTimeoutIds: [...(getState().pollingTimeoutIds ?? []), timeoutId] });
	};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	flagTitle: {
		id: 'servicedesk-cmdb-async-actions.common.flags.completed-flag-bulk-export.flag-title',
		defaultMessage: `{objectsProcessed, plural,
    		=0 {Action complete: Export}
    		one {Action complete: Export {objectsProcessed} object}
    		other {Action complete: Export {objectsProcessed} objects}
		}`,
		description: 'Flag title when Bulk export action is completed',
	},
	downloadExportNonfinal: {
		id: 'servicedesk-cmdb-async-actions.common.flags.completed-bulk-export-flag.download-export-nonfinal',
		defaultMessage: 'Download export',
		description: 'Flag action to download export',
	},
});

import React, { useEffect, useState } from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { cmdbDataManagerChangeBoardingEntryPoint } from '@atlassian/jira-servicedesk-cmdb-data-manager-change-boarding/entrypoint.tsx';
import { CmdbDataManagerChangeBoarding } from '@atlassian/jira-servicedesk-cmdb-data-manager-change-boarding/src/ui/index.tsx';

export const DataManagerGAChangeBoardingModal = () => {
	const [isDataManagerModalLoaded, setDataManagerModalLoaded] = useState(false);
	const { entryPointActions } = useEntryPoint(cmdbDataManagerChangeBoardingEntryPoint, {});

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (!isDataManagerModalLoaded) {
			entryPointActions.load();
			setDataManagerModalLoaded(true);
		}
	}, [entryPointActions, isDataManagerModalLoaded]);

	return <CmdbDataManagerChangeBoarding />;
};

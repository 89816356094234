import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noResultsFoundMessage: {
		id: 'servicedesk-insight-rapid-search-bar.popup-content.no-results-found-message',
		defaultMessage: "We couldn't find any results matching your search",
		description: 'Message informing the user that there were no object results',
	},
	objects: {
		id: 'servicedesk-insight-rapid-search-bar.popup-content.objects',
		defaultMessage: 'Objects',
		description: 'Label for a list of objects',
	},
	objectTypes: {
		id: 'servicedesk-insight-rapid-search-bar.popup-content.object-types',
		defaultMessage: 'Object types',
		description: 'Label for a list of object types',
	},
	noneFound: {
		id: 'servicedesk-insight-rapid-search-bar.popup-content.none-found',
		defaultMessage: 'None found',
		description: "Message shown when no objects or object types are found for the user's search",
	},
	typeMoreToSeeResults: {
		id: 'servicedesk-insight-rapid-search-bar.popup-content.type-more-to-see-results',
		defaultMessage: 'Keep typing to see search results',
		description: 'Informs the user to type more keys on their keyboard to get search results',
	},
	objectSearchResultsScreenReaderMessage: {
		id: 'servicedesk-insight-rapid-search-bar.popup-content.object-search-results-screen-reader-message',
		defaultMessage:
			'{objectCount, plural, =0 {no objects found} one {one object found} other {{objectCount} objects found}}.',
		description:
			'Message the screen reader will read with the number of search matches for objects',
	},
	objectTypeSearchResultsScreenReaderMessage: {
		id: 'servicedesk-insight-rapid-search-bar.popup-content.object-type-search-results-screen-reader-message',
		defaultMessage:
			'{objectTypeCount, plural, =0 {no object types found} one {one object type found} other {{objectTypeCount} object types found}}.',
		description:
			'Message the screen reader will read with the number of search matches for object types',
	},
	errorOccurredScreenReaderMessage: {
		id: 'servicedesk-insight-rapid-search-bar.popup-content.error-occurred-screen-reader-message',
		defaultMessage: 'An error occurred while searching, please try again.',
		description:
			'Message the screen reader will read when an error occurs while searching in the quick search',
	},
});

import { submitBulkDeleteTask } from './submit-async-delete-task/index.tsx';
import { submitBulkEditTask } from './submit-async-edit-task/index.tsx';
import { submitBulkExportTask } from './submit-async-export-task/index.tsx';
import { submitBulkQrCodeTask } from './submit-async-qr-code-task/index.tsx';

// Only expose the actions that are used by hooks
export const actions = {
	submitBulkExportTask,
	submitBulkQrCodeTask,
	submitBulkDeleteTask,
	submitBulkEditTask,
} as const;

export type Actions = typeof actions;

import { performCmdbPostRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getCmdbTaskArchiveUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/task.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { TaskId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';

export const dismissTaskById = (workspaceId: WorkspaceId, taskId: TaskId): Promise<void> => {
	return performCmdbPostRequest(getCmdbTaskArchiveUrl(workspaceId, taskId), {
		body: JSON.stringify({ dismissed: true }),
	});
};

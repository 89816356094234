import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { TaskId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { dismissTaskById } from '../../services/dismiss-task-by-id/index.tsx';
import type { CmdbAsyncActionStoreAction } from '../../types.tsx';

export type DismissTaskProps = {
	taskId: TaskId;
};

export const dismissTask =
	({ taskId }: DismissTaskProps): CmdbAsyncActionStoreAction<Promise<void>> =>
	async ({ getState, setState }, { workspaceId, createAnalyticsEvent }) => {
		// This is just to prevent multiple dismiss requests for the same task, won't ever be shown in the UI
		if (getState().isDismissing.includes(taskId)) {
			return;
		}
		setState({ isDismissing: [...getState().isDismissing, taskId] });
		try {
			await dismissTaskById(workspaceId, taskId);
			setState({
				tasks: [...(getState().tasks ?? [])?.filter((task) => task.taskId !== taskId)],
				isDismissing: [
					...getState().isDismissing.filter((existingTaskId) => existingTaskId !== taskId),
				],
			});
			fireTrackAnalytics(createAnalyticsEvent({}), 'dismissTask succeeded');
		} catch (error) {
			fireErrorAnalytics({
				error: error instanceof Error ? error : undefined,
				meta: {
					id: 'dismissTask',
					packageName: 'jiraServicedeskCmdbAsyncActions',
					teamName: 'falcons',
				},
			});
			setState({
				isDismissing: [
					...getState().isDismissing.filter((existingTaskId) => existingTaskId !== taskId),
				],
			});
			// We are intentionally swallowing the error here as we don't want a failure to be a blocking action and should be hidden from the user
		}
	};

/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { AppSkeletonImage } from '../../common/ui/AppSkeletonImage.tsx';
import objectListSVG from './assets/insight-recent-object-container-skeleton.tpl.svg';
import schemaListSVG from './assets/insight-schema-container-skeleton.tpl.svg';

const skeletonCssStyles = css({
	// increase self specificity to ensure deterministic override
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		border: 'none',
	},
});

export const InsightSchemaContainerSkeleton = () => (
	<AppSkeletonImage
		src={schemaListSVG}
		css={skeletonCssStyles}
		containerStyles={{ maxHeight: '30em' }}
	/>
);

export const InsightRecentObjectContainerSkeleton = () => (
	<AppSkeletonImage src={objectListSVG} css={skeletonCssStyles} />
);

import { performCmdbPostRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getCmdbAsyncObjectExportUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { BulkActionSubmitResponse } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import type { AsyncBulkExportParams } from './types.tsx';

export const createAsyncBulkExportTask = (
	workspaceId: WorkspaceId,
	params: AsyncBulkExportParams,
): Promise<BulkActionSubmitResponse> =>
	performCmdbPostRequest(getCmdbAsyncObjectExportUrl(workspaceId), {
		body: JSON.stringify(params),
	});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	flagTitle: {
		id: 'servicedesk-cmdb-async-actions.common.flags.completed-flag-bulk-delete.flag-title',
		defaultMessage: `{objectsProcessed, plural,
    		=0 {Action complete: Delete}
    		one {Action complete: Delete {objectsProcessed} object}
    		other {Action complete: Delete {objectsProcessed} objects}
		}`,
		description: 'Flag title when Bulk delete action is completed',
	},
});

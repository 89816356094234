import type { ObjectAttributeType } from './attribute-types.tsx';
import type { CmdbBulkEditOperationType } from './cmdb-bulk-edit-modal.tsx';
import type { CmdbObject } from './cmdb-object.tsx';

type Modify<T, R> = Omit<T, keyof R> & R;

// Bulk Object tasks
export const OPERATION_TYPE_BULK_EDIT = 'BulkEdit';
export const OPERATION_TYPE_BULK_DELETE = 'BulkDelete';
export const OPERATION_TYPE_BULK_EXPORT = 'BulkExport';
export const OPERATION_TYPE_BULK_QR_CODE = 'QrCode';

export const STATUS_QUEUED = 'Queued';
export const STATUS_IN_PROGRESS = 'InProgress';
export const STATUS_COMPLETED = 'Completed';
export const STATUS_ARCHIVED = 'Archived';

type FinishedStatus = typeof STATUS_COMPLETED | typeof STATUS_ARCHIVED;

type ObjectTaskOperationType =
	| typeof OPERATION_TYPE_BULK_EDIT
	| typeof OPERATION_TYPE_BULK_DELETE
	| typeof OPERATION_TYPE_BULK_EXPORT
	| typeof OPERATION_TYPE_BULK_QR_CODE;

export type TaskOperationType = ObjectTaskOperationType;

export type TaskStatus =
	| typeof STATUS_QUEUED
	| typeof STATUS_IN_PROGRESS
	| typeof STATUS_COMPLETED
	| typeof STATUS_ARCHIVED;

export type TaskLink = {
	rel: string;
	href: string;
	type: string;
};

export type TaskId = string;

export const toTaskId = (id: string): TaskId => id;

type BaseTask = {
	taskId: TaskId;
	links?: TaskLink[];
};

type ErroneousObjectsMap = {
	[OPERATION_TYPE_BULK_EDIT]: BulkEditErroneousObject[];
};

type OperationType<T> = {
	operationType: T;
};

type Status<S> = {
	status: S;
};

type ResultPayload<R> = {
	resultPayload: R;
};

type ErroneousObjects<E> = {
	erroneousObjects: E;
};

type ObjectResultPayload = {
	objectsProcessed: number;
	objectsEncounteredError: number;
	hasError: boolean;
};

type ObjectTaskResultPayload<
	T extends ObjectTaskOperationType,
	HasErroneousObjects extends boolean = false,
> = T extends keyof ErroneousObjectsMap
	? HasErroneousObjects extends true
		? ObjectResultPayload & ErroneousObjects<ErroneousObjectsMap[T]>
		: ObjectResultPayload
	: ObjectResultPayload;

type GetObjectTask<
	OT extends ObjectTaskOperationType,
	TS extends TaskStatus | undefined = undefined,
	HasErroneousObjects extends boolean = false,
> = TS extends undefined
	? BaseTask & OperationType<OT>
	: TS extends FinishedStatus
		? BaseTask &
				OperationType<OT> &
				Status<TS> &
				ResultPayload<ObjectTaskResultPayload<OT, HasErroneousObjects>>
		: BaseTask & OperationType<OT> & Status<TS>;

type BulkEditTask = GetObjectTask<typeof OPERATION_TYPE_BULK_EDIT>;
type BulkDeleteTask = GetObjectTask<typeof OPERATION_TYPE_BULK_DELETE>;
type BulkExportTask = GetObjectTask<typeof OPERATION_TYPE_BULK_EXPORT>;
type BulkQrCodeTask = GetObjectTask<typeof OPERATION_TYPE_BULK_QR_CODE>;

type BaseObjectTask = BulkEditTask | BulkDeleteTask | BulkExportTask | BulkQrCodeTask;

export type CompletedBulkEditTask = GetObjectTask<
	typeof OPERATION_TYPE_BULK_EDIT,
	typeof STATUS_COMPLETED
>;

export type CompletedBulkDeleteTask = GetObjectTask<
	typeof OPERATION_TYPE_BULK_DELETE,
	typeof STATUS_COMPLETED
>;

export type CompletedBulkExportTask = GetObjectTask<
	typeof OPERATION_TYPE_BULK_EXPORT,
	typeof STATUS_COMPLETED
>;

export type CompletedBulkQrCodeTask = GetObjectTask<
	typeof OPERATION_TYPE_BULK_QR_CODE,
	typeof STATUS_COMPLETED
>;

type ArchivedBulkEditTask = Modify<CompletedBulkEditTask, Status<typeof STATUS_ARCHIVED>>;
type ArchivedBulkDeleteTask = Modify<CompletedBulkDeleteTask, Status<typeof STATUS_ARCHIVED>>;
type ArchivedBulkExportTask = Modify<CompletedBulkExportTask, Status<typeof STATUS_ARCHIVED>>;
type ArchivedBulkQrCodeTask = Modify<CompletedBulkQrCodeTask, Status<typeof STATUS_ARCHIVED>>;

type ArchivedObjectTask =
	| ArchivedBulkEditTask
	| ArchivedBulkDeleteTask
	| ArchivedBulkExportTask
	| ArchivedBulkQrCodeTask;

export type CompletedObjectTask =
	| CompletedBulkEditTask
	| CompletedBulkDeleteTask
	| CompletedBulkExportTask
	| CompletedBulkQrCodeTask;

type PendingObjectTask = BaseObjectTask & Status<typeof STATUS_QUEUED | typeof STATUS_IN_PROGRESS>;

type ObjectTask = PendingObjectTask | CompletedObjectTask | ArchivedObjectTask;

export type ErroneousObjectDetails = Pick<CmdbObject, 'id' | 'objectKey' | 'label' | 'avatar'>;

type ErroneousObject<T> = {
	object: ErroneousObjectDetails;
	errors: T[];
};

type BulkEditError = {
	objectTypeAttribute: Pick<ObjectAttributeType, 'name'>;
	operationType: CmdbBulkEditOperationType;
	errorMessage: string;
};

type BulkEditErroneousObject = ErroneousObject<BulkEditError>;

export type CompletedBulkEditTaskWithErroneousObjects = GetObjectTask<
	typeof OPERATION_TYPE_BULK_EDIT,
	typeof STATUS_COMPLETED,
	true
>;

export type Task = ObjectTask;
export type CompletedTask = CompletedObjectTask;
export type ResultsModalTask = CompletedBulkEditTaskWithErroneousObjects | CompletedBulkDeleteTask;

// REST API responses
export type BulkActionSubmitResponse = Pick<Task, 'taskId' | 'operationType'>;
export type BulkActionTasksResponse = { tasks: Task[] };

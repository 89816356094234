// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import AssetsSchemaIcon from '@atlaskit/icon-lab/core/assets-schema';
import QrCodeIconAk from '@atlaskit/icon-lab/core/qr-code';
import type { NewCoreIconProps } from '@atlaskit/icon/types';
import { Box, xcss } from '@atlaskit/primitives';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { createCustomAKIcon } from './common/utils/index.tsx';

export const ObjectSchemasGlyph = (props: ComponentProps<'svg'>) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 8C14.8954 8 14 8.89543 14 10V12C14 13.1046 14.8954 14 16 14H18C19.1046 14 20 13.1046 20 12V10C20 8.89543 19.1046 8 18 8H16ZM18 10H16V12H18V10Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 16C14.8954 16 14 16.8954 14 18V20C14 21.1046 14.8954 22 16 22H18C19.1046 22 20 21.1046 20 20V18C20 16.8954 19.1046 16 18 16H16ZM18 18H16V20H18V18Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6 2C4.89543 2 4 2.89543 4 4V6C4 7.10457 4.89543 8 6 8H8C9.10457 8 10 7.10457 10 6V4C10 2.89543 9.10457 2 8 2H6ZM8 4H6V6H8V4Z"
			fill="currentColor"
		/>
		<rect x="6" y="7" width="2" height="11" fill="currentColor" />
		<path d="M6 18H15V20H8C6.89543 20 6 19.1046 6 18Z" fill="currentColor" />
		<path d="M6 10H15V12H8C6.89543 12 6 11.1046 6 10Z" fill="currentColor" />
	</svg>
);

export const ObjectSchemasIcon = componentWithCondition(
	isVisualRefreshEnabled,
	AssetsSchemaIcon,
	createCustomAKIcon(ObjectSchemasGlyph),
);

export const ObjectGraphGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
		<circle cx="4" cy="4" r="2" fill="currentColor" />
		<circle cx="6" cy="18" r="2" fill="currentColor" />
		<circle cx="20" cy="6" r="2" fill="currentColor" />
		<circle cx="18" cy="19" r="2" fill="currentColor" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 15a4 4 0 100-8 4 4 0 000 8zm0-2a2 2 0 100-4 2 2 0 000 4z"
			fill="currentColor"
		/>
		<path d="M4 4l6 5.5m9.5-3l-5 3m-8 8l4-4.5m8 6.5l-5-6" stroke="currentColor" strokeWidth="1.5" />
	</svg>
);

export const ObjectGraphIcon = createCustomAKIcon(ObjectGraphGlyph);

const ShowObjectGraphDetailsGlyph = (props: ComponentProps<'svg'>) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M20 2H2V4H9V9H11V4H20V20H11V15H9V20H2V22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2Z"
			fill="currentColor"
		/>
		<path
			d="M2.47 12.8835C2.19 12.7165 2 12.3835 2 11.9995C2 11.6155 2.19 11.2825 2.47 11.1155L5.086 8.49946C5.2746 8.3173 5.5272 8.21651 5.7894 8.21879C6.0516 8.22107 6.30241 8.32623 6.48782 8.51164C6.67323 8.69705 6.7784 8.94786 6.78067 9.21006C6.78295 9.47226 6.68216 9.72486 6.5 9.91346L4.938 10.9995H10.118C10.605 10.9995 11 11.4475 11 11.9995C11 12.5515 10.605 12.9995 10.118 12.9995H4.938L6.5 14.0855C6.68216 14.2741 6.78295 14.5267 6.78067 14.7889C6.7784 15.0511 6.67323 15.3019 6.48782 15.4873C6.30241 15.6727 6.0516 15.7779 5.7894 15.7801C5.5272 15.7824 5.2746 15.6816 5.086 15.4995L2.47 12.8835Z"
			fill="currentColor"
		/>
		<path
			d="M13 7C13 6.44772 13.4477 6 14 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H14C13.4477 8 13 7.55228 13 7Z"
			fill="currentColor"
		/>
		<path
			d="M13 11C13 10.4477 13.4477 10 14 10H17C17.5523 10 18 10.4477 18 11C18 11.5523 17.5523 12 17 12H14C13.4477 12 13 11.5523 13 11Z"
			fill="currentColor"
		/>
		<path
			d="M13 15C13 14.4477 13.4477 14 14 14H17C17.5523 14 18 14.4477 18 15C18 15.5523 17.5523 16 17 16H14C13.4477 16 13 15.5523 13 15Z"
			fill="currentColor"
		/>
	</svg>
);

const HideObjectGraphDetailsGlyph = (props: ComponentProps<'svg'>) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19 2H2V4H14V9H16V4H19V20H16V15H14V20H2V22H19C20.1046 22 21 21.1046 21 20V4C21 2.89543 20.1046 2 19 2Z"
			fill="currentColor"
		/>
		<path
			d="M12.53 12.8835C12.81 12.7165 13 12.3835 13 11.9995C13 11.6155 12.81 11.2825 12.53 11.1155L9.914 8.49946C9.7254 8.3173 9.4728 8.21651 9.2106 8.21879C8.9484 8.22107 8.69759 8.32623 8.51218 8.51164C8.32677 8.69705 8.2216 8.94786 8.21933 9.21006C8.21705 9.47226 8.31784 9.72486 8.5 9.91346L10.062 10.9995H2.882C2.395 10.9995 2 11.4475 2 11.9995C2 12.5515 2.395 12.9995 2.882 12.9995H10.062L8.5 14.0855C8.31784 14.2741 8.21705 14.5267 8.21933 14.7889C8.2216 15.0511 8.32677 15.3019 8.51218 15.4873C8.69759 15.6727 8.9484 15.7779 9.2106 15.7801C9.4728 15.7824 9.7254 15.6816 9.914 15.4995L12.53 12.8835Z"
			fill="currentColor"
		/>
		<path d="M14 9H16V15H14V9Z" fill="currentColor" />
	</svg>
);

export const ShowObjectGraphDetailsIcon = createCustomAKIcon(ShowObjectGraphDetailsGlyph);

export const HideObjectGraphDetailsIcon = createCustomAKIcon(HideObjectGraphDetailsGlyph);

const SchemaGlyph = () => (
	<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="0.5" width="47" height="47" rx="4.5" fill="white" stroke="#C1C7D0" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M28.6665 18.6667C27.5619 18.6667 26.6665 19.5621 26.6665 20.6667V24.6667C26.6665 25.7713 27.5619 26.6667 28.6665 26.6667H32.6665C33.7711 26.6667 34.6665 25.7713 34.6665 24.6667V20.6667C34.6665 19.5621 33.7711 18.6667 32.6665 18.6667H28.6665ZM31.9998 21.3334H29.3331V24.0001H31.9998V21.3334Z"
			fill="#42526E"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M28.6665 29.3333C27.5619 29.3333 26.6665 30.2287 26.6665 31.3333V35.3333C26.6665 36.4379 27.5619 37.3333 28.6665 37.3333H32.6665C33.7711 37.3333 34.6665 36.4379 34.6665 35.3333V31.3333C34.6665 30.2287 33.7711 29.3333 32.6665 29.3333H28.6665ZM31.9998 31.9999H29.3331V34.6666H31.9998V31.9999Z"
			fill="#42526E"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.3335 10.6667C14.2289 10.6667 13.3335 11.5621 13.3335 12.6667V16.6667C13.3335 17.7713 14.2289 18.6667 15.3335 18.6667H19.3335C20.4381 18.6667 21.3335 17.7713 21.3335 16.6667V12.6667C21.3335 11.5621 20.4381 10.6667 19.3335 10.6667H15.3335ZM18.6669 13.3334H16.0002V16.0001H18.6669V13.3334Z"
			fill="#42526E"
		/>
		<rect x="16" y="17.3333" width="2.66667" height="14.6667" fill="#42526E" />
		<path d="M16 32H28V34.6667H18C16.8954 34.6667 16 33.7712 16 32.6667V32Z" fill="#42526E" />
		<path d="M16 21.3333H28V24H18C16.8954 24 16 23.1046 16 22V21.3333Z" fill="#42526E" />
	</svg>
);

export const SchemaIcon = componentWithCondition(
	isVisualRefreshEnabled,
	(props: NewCoreIconProps) => (
		<Box xcss={iconBorder}>
			<AssetsSchemaIcon {...props} />
		</Box>
	),
	createCustomAKIcon(SchemaGlyph),
);

const ColumnsGlyph = (props: ComponentProps<'svg'>) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4 6H2V8V20V22H4H7H9V20V19H7V20H4V8H5V6H4Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20 8H17V20H20V8ZM15 6V22H22V6H15Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 4H9V15H12V4ZM7 2V17H14V2H7Z"
			fill="currentColor"
		/>
	</svg>
);

export const ColumnsIcon = createCustomAKIcon(ColumnsGlyph);

const QrCodeGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width="24" height="24" viewBox="0 0 24 24">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2 4V7H4V4H7V2H4H2V4ZM4 17H2V20V22H4H7V20H4V17ZM22 4V7H20V4H17V2H20H22V4ZM22 17H20V20H17V22H20H22V20V17ZM19 9H13V11H19V9ZM8 13H11V15H8V13ZM11 17H8V19H11V17ZM13 5H15V8H13V5ZM7 13H5V19H7V13ZM17 5H19V8H17V5ZM11 5H5V11H11V5ZM9 7H7V9H9V7ZM13 13H19V19H13V13ZM15 15H17V17H15V15Z"
			fill="currentColor"
		/>
	</svg>
);

export const QrCodeIcon = componentWithCondition(
	isVisualRefreshEnabled,
	(props: NewCoreIconProps) => <QrCodeIconAk spacing="spacious" {...props} />,
	createCustomAKIcon(QrCodeGlyph),
);

const iconBorder = xcss({
	width: '24px',
	height: '24px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	border: 'solid 1px',
	borderRadius: '3px',
	borderColor: 'color.border',
});

import { performCmdbPostRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getCmdbAsyncBulkQrCodeUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { BulkActionSubmitResponse } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import type { AsyncBulkQrCodeParams } from './types.tsx';

export const createAsyncBulkQrCodeTask = (
	workspaceId: WorkspaceId,
	size: number,
	params: AsyncBulkQrCodeParams,
): Promise<BulkActionSubmitResponse> =>
	performCmdbPostRequest(getCmdbAsyncBulkQrCodeUrl(workspaceId, { size }), {
		body: JSON.stringify(params),
	});

import type {
	Task,
	TaskId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';

export const mergeTasks = (existingTasks?: Task[], newTasks?: Task[]) => {
	const taskMap = new Map<TaskId, Task>();
	(existingTasks ?? []).forEach((item) => taskMap.set(item.taskId, item));
	(newTasks ?? []).forEach((item) =>
		taskMap.set(item.taskId, { ...taskMap.get(item.taskId), ...item }),
	);
	return Array.from(taskMap.values());
};

import React from 'react';
import Link, { type LinkProps } from '@atlaskit/link';
import { FormattedMessage } from '@atlassian/jira-intl/src/index.tsx';
import type { MessageDescriptorV2 } from '@atlassian/jira-intl/src/v2/types.tsx';

type Props = {
	message: MessageDescriptorV2;
	linkProps: LinkProps;
};

/**
 *
 * Allows rendering an atlaskit link in a jira-intl message.
 *
 * Accepts a jira-intl message with link tags
 * i.e. `<link>Learn more about X</link>`
 *
 * The contents of the link tags
 * will be wrapped with an atlaskit `<Link />` component that the
 * consumer can supply props to.
 */
export const FormattedLinkMessage = ({ message, linkProps }: Props) => {
	return (
		<FormattedMessage
			{...message}
			values={{
				link: (chunks) => <Link {...linkProps}>{chunks}</Link>,
			}}
		/>
	);
};

import { performCmdbPostRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getCmdbAsyncBulkEditUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { BulkActionSubmitResponse } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import type { AsyncBulkEditParams } from './types.tsx';

export const createAsyncBulkEditTask = (
	workspaceId: WorkspaceId,
	params: AsyncBulkEditParams,
): Promise<BulkActionSubmitResponse> =>
	performCmdbPostRequest(getCmdbAsyncBulkEditUrl(workspaceId), {
		body: JSON.stringify({
			objectEntry: {
				objectTypeId: params.filters.objectTypeId,
				attributes: params.attributeOperations,
			},
			filters: params.filters,
		}),
	});

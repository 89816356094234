import { performCmdbPostRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getCmdbAsyncBulkDeleteUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { BulkActionSubmitResponse } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import type { AsyncBulkDeleteParams } from './types.tsx';

export const createAsyncBulkDeleteTask = (
	workspaceId: WorkspaceId,
	params: AsyncBulkDeleteParams,
): Promise<BulkActionSubmitResponse> =>
	performCmdbPostRequest(getCmdbAsyncBulkDeleteUrl(workspaceId), {
		body: JSON.stringify(params),
	});

/** @jsx jsx */
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { styled as styled2, jsx } from '@compiled/react';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import Heading from '@atlaskit/heading';
import { Box, xcss, Inline } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { AsyncCBPChangeboarding } from '@atlassian/jira-cbp-changeboarding/src/index.tsx';
import { IntercomChatWithErrorBoundry } from '@atlassian/jira-cmdb-intercom-integration/src/ui/index.tsx';
import PageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	fireUIAnalytics,
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { AsyncCmdbCompassChangeboarding } from '@atlassian/jira-servicedesk-cmdb-compass-changeboarding/src/async.tsx';
import { LimitsBanner } from '@atlassian/jira-servicedesk-cmdb-object-usage-messaging/src/ui/limits-banner/index.tsx';
import { ObjectUsageProvider } from '@atlassian/jira-servicedesk-cmdb-object-usage-messaging/src/utils/store/index.tsx';
import { PreReleaseBanner } from '@atlassian/jira-servicedesk-feature-usage-common/src/ui/pre-release-banner/index.tsx';
import { useWorkspaceContext } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/controllers/workspace-context/index.tsx';
import {
	useDataManager,
	useInsightGlobalConfig,
} from '@atlassian/jira-servicedesk-insight-global-configuration-store/src/services/index.tsx';
import { useLandingPageState } from '@atlassian/jira-servicedesk-insight-landing-page-store/src/controllers/store/index.tsx';
import { ObjectContainer as InsightObjectContainer } from '@atlassian/jira-servicedesk-insight-object-container/src/ui/index.tsx';
import { RapidSearchBar as InsightRapidSearchBar } from '@atlassian/jira-servicedesk-insight-rapid-search-bar/src/ui/index.tsx';
import { SchemaContainer as InsightSchemaContainer } from '@atlassian/jira-servicedesk-insight-schema-container/src/ui/index.tsx';
import {
	DATA_MANAGER_ROLES,
	DATA_MANAGER_ADAPTERS_ROLES,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/data-manager-types.tsx';
import { toSchemaId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { FormattedLinkMessage } from '@atlassian/jira-servicedesk-insight-shared-ui/src/ui/formatted-link-message/index.tsx';
import {
	getLandingPageUrl,
	getAssetsReportsUrl,
	getDataManagerAdaptersUrl,
	getDataManagerUrl,
	getGlobalConfigUrl,
} from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { Link } from '@atlassian/react-resource-router';
import { DataManagerGAChangeBoardingModal } from '../common/ui/data-manager-ga-change-boarding-modal/index.tsx';
import { ConfigLink } from './config-link/index.tsx';
import { DataManagerLink } from './data-manager-link/index.tsx';
import { messages } from './messages.tsx';
import { AsyncInsightLandingPageReportsLink } from './reports-link/index.tsx';
import { UsagePanel } from './usage-panel/index.tsx';

export const LandingPage = () => {
	const { formatMessage } = useIntl();
	const { workspaceId } = useWorkspaceContext();
	const [{ schemaState }] = useLandingPageState();
	const [{ config: globalConfigData }] = useInsightGlobalConfig();
	const [
		{
			isProvisioned,
			provisioningLoading,
			myRoles,
			myRolesLoading,
			provisioningError,
			myRolesError,
		},
		{ fetchProvisioningStatus, fetchMyDataManagerRoles },
	] = useDataManager();

	const [isSchemaModalOpen, setIsSchemaModalOpen] = useState(false);

	const handleSchemaModalState = (isOpen: boolean) => {
		setIsSchemaModalOpen(isOpen);
	};

	const {
		insightAdministrator: isAdmin,
		restrictedObjectSchemaIds,
		insightReporting,
		limitInfo: { objectCount, objectLimit, billingCycle, limitState = 'disabled' },
	} = globalConfigData ?? {
		insightAdministrator: false,
		restrictedObjectSchemaIds: [],
		insightReporting: false,
		limitInfo: {
			objectCount: 0,
			objectLimit: Number.MAX_SAFE_INTEGER,
			billingCycle: 'monthly',
			limitState: 'disabled',
		},
	};
	const isSiteAdmin = useIsSiteAdmin();

	const dataManagerLink = useMemo(() => getDataManagerUrl(), []);
	const adaptersLink = useMemo(() => getDataManagerAdaptersUrl(), []);

	const configLink = useMemo(() => getGlobalConfigUrl(), []);
	const reportsLink = useMemo(() => getAssetsReportsUrl(), []);
	// @ts-expect-error - TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
	const onClickConfigLink = useCallback((analyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'servicedeskInsightGlobalConfigPageNavigation');
	}, []);

	// @ts-expect-error - TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
	const onClickReportsLink = useCallback((analyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'servicedeskAssetsReportsPageNavigation');
	}, []);

	useEffect(() => {
		if (myRoles === undefined && myRolesLoading !== true && myRolesError === undefined) {
			fetchMyDataManagerRoles();
		}
	}, [fetchMyDataManagerRoles, myRoles, myRolesError, myRolesLoading]);

	useEffect(() => {
		if (
			isProvisioned === undefined &&
			provisioningLoading !== true &&
			provisioningError === undefined
		) {
			fetchProvisioningStatus();
		}
	}, [fetchProvisioningStatus, isProvisioned, provisioningError, provisioningLoading]);

	if (!workspaceId) {
		return null;
	}

	const showDataManagerLink = () => {
		if (isProvisioned && myRoles) {
			const hasDMRoles = myRoles.some((myRole) => DATA_MANAGER_ROLES.includes(myRole));
			const hasAdapterRoles = myRoles.some((myRole) =>
				DATA_MANAGER_ADAPTERS_ROLES.includes(myRole),
			);
			if (hasDMRoles) {
				return (
					<DataManagerLink to={dataManagerLink} app="data-manager">
						{formatMessage(messages.dataManagerLink)}
					</DataManagerLink>
				);
			}

			if (hasAdapterRoles) {
				return (
					<DataManagerLink to={adaptersLink} app="adapters">
						{formatMessage(messages.adaptersLink)}
					</DataManagerLink>
				);
			}
			return undefined;
		}
		return undefined;
	};

	const isInsightReportsEnabled = () =>
		ff('jsm-assets-reporting_seqk0') && (isSiteAdmin || isAdmin) && insightReporting;

	const documentTitle = messages.documentTitleCmdbAssets;

	const navRefreshTitle = messages.navRefreshAssetsSchemasTile;

	const renderLandingPageContent = () =>
		getWillShowNav4() ? (
			<Box xcss={overflowStyle}>
				<Box xcss={breadCrumbsWrapper}>
					<Breadcrumbs>
						<BreadcrumbsItem
							testId="servicedesk-insight-landing-page.ui.breadcrumbs-item-assets"
							href={getLandingPageUrl()}
							text={formatMessage(messages.assetsBreadcrumbs)}
							component={Link}
						/>
					</Breadcrumbs>
				</Box>
				<DocumentTitle title={formatMessage(navRefreshTitle)} />

				<PageHeader disableTitleStyles>
					<PageHeaderWrapper>
						<AsyncCBPChangeboarding>
							<Heading size="large">{formatMessage(navRefreshTitle)}</Heading>
						</AsyncCBPChangeboarding>
					</PageHeaderWrapper>
				</PageHeader>
				<Box xcss={dividerStyles} />
				<Box xcss={navRefreshPageContainerStyle}>
					<AsyncCmdbCompassChangeboarding />
					<Inline space="space.500">
						<Box xcss={[leftColumnNavRefreshStyles, minWidthStyles]}>
							<PageDescription>
								<FormattedLinkMessage
									message={messages.descriptionCmdbAssets}
									linkProps={{
										target: '_blank',
										href: 'https://support.atlassian.com/jira-service-management-cloud/docs/get-to-know-asset-and-service-management-with-insight/',
									}}
								/>
							</PageDescription>
						</Box>
						<Box xcss={rightColumnNavRefreshStyles} />
					</Inline>
					<PageBodyContainer>
						<Inline space="space.500">
							<Box xcss={leftColumnNavRefreshStyles}>
								<InsightRapidSearchBar
									workspaceId={workspaceId}
									restrictedObjectSchemaIds={restrictedObjectSchemaIds.map((id) =>
										toSchemaId(String(id)),
									)}
									renderSearchTextfield={({ searchTextfieldProps }) => (
										<>
											<Box id="searchDescriptionLabel" xcss={screenReaderOnlyStyles}>
												{formatMessage(messages.searchPlaceholderAriaLabel)}
											</Box>
											<Textfield
												id="searchDescription"
												testId="servicedesk-insight-landing-page.ui.textfield"
												placeholder={formatMessage(messages.searchPlaceholder)}
												aria-label={formatMessage(messages.searchPlaceholder)}
												aria-describedby="searchDescriptionLabel"
												{...searchTextfieldProps}
											/>
										</>
									)}
								/>
							</Box>
							<Box xcss={rightColumnNavRefreshStyles} />
						</Inline>
						<PageRowContainer>
							<Box xcss={leftColumnNavRefreshStyles}>
								<InsightSchemaContainer onSchemaModalStateChange={handleSchemaModalState} />
							</Box>
							<Box xcss={rightColumnNavRefreshStyles}>
								<UsagePanel />
								<InsightObjectContainer />
							</Box>
						</PageRowContainer>
					</PageBodyContainer>
					<DataManagerGAChangeBoardingModal />
				</Box>
			</Box>
		) : (
			<PageContainer>
				<DocumentTitle title={formatMessage(documentTitle)} />
				<PageHeader disableTitleStyles>
					<PageHeaderWrapper>
						<AsyncCBPChangeboarding>
							<Heading size="large">{formatMessage(documentTitle)}</Heading>
						</AsyncCBPChangeboarding>
						<LinksContainer>
							{showDataManagerLink()}

							{isAdmin && (
								<ConfigLink to={configLink} onClick={onClickConfigLink}>
									{formatMessage(messages.configLink)}
								</ConfigLink>
							)}
							{isInsightReportsEnabled() && (
								<AsyncInsightLandingPageReportsLink to={reportsLink} onClick={onClickReportsLink}>
									{formatMessage(messages.reportsLink)}
								</AsyncInsightLandingPageReportsLink>
							)}
						</LinksContainer>
					</PageHeaderWrapper>
				</PageHeader>
				<AsyncCmdbCompassChangeboarding />
				<DataManagerGAChangeBoardingModal />
				<PageDescription>
					<FormattedLinkMessage
						message={messages.descriptionCmdbAssets}
						linkProps={{
							target: '_blank',
							href: 'https://support.atlassian.com/jira-service-management-cloud/docs/get-to-know-asset-and-service-management-with-insight/',
						}}
					/>
				</PageDescription>
				<PageBodyContainer>
					<InsightRapidSearchBar
						workspaceId={workspaceId}
						restrictedObjectSchemaIds={restrictedObjectSchemaIds.map((id) =>
							toSchemaId(String(id)),
						)}
						renderSearchTextfield={({ searchTextfieldProps }) => (
							<Textfield
								testId="servicedesk-insight-landing-page.ui.textfield"
								placeholder={formatMessage(messages.searchPlaceholder)}
								aria-label={formatMessage(messages.searchPlaceholder)}
								{...searchTextfieldProps}
							/>
						)}
					/>
					<PageRowContainer>
						<Box xcss={leftColumnStyles}>
							<InsightSchemaContainer onSchemaModalStateChange={handleSchemaModalState} />
						</Box>
						<RightColumn>
							<UsagePanel />
							<InsightObjectContainer />
						</RightColumn>
					</PageRowContainer>
				</PageBodyContainer>
			</PageContainer>
		);

	return (
		<ContextualAnalyticsData
			sourceName="servicedeskInsightLandingPage"
			sourceType={SCREEN}
			attributes={{
				isSiteAdmin,
			}}
		>
			{schemaState.type === 'success' && (
				<FireScreenAnalytics
					attributes={{
						numberOfSchemas: schemaState.schemas.length,
					}}
				/>
			)}
			{fg('jsm_cbp_pre-release_banner') && <PreReleaseBanner location="assets" />}
			{fg('jsm_assets_cbp_limits_and_notifications') ? (
				<ObjectUsageProvider
					isJiraAdmin={isAdmin}
					objectCount={objectCount}
					objectLimit={objectLimit}
					billingPeriod={billingCycle === 'annual' ? 'annual' : 'monthly'}
					limitState={limitState}
				>
					<LimitsBanner displayWithOffset />
					{renderLandingPageContent()}
					{!getWillShowNav4() && !isSchemaModalOpen && <IntercomChatWithErrorBoundry />}
				</ObjectUsageProvider>
			) : (
				<>
					{renderLandingPageContent()}
					{!getWillShowNav4() && !isSchemaModalOpen && <IntercomChatWithErrorBoundry />}
				</>
			)}
		</ContextualAnalyticsData>
	);
};

const LEFT_COLUMN_MIN_WIDTH = 260;

const LEFT_COLUMN_NAV_REFRESH_MIN_WIDTH = 376;

const RIGHT_COLUMN_NAV_REFRESH_MIN_WIDTH = 240;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageHeaderWrapper = styled2.div({
	justifyContent: 'space-between',
	flexFlow: 'wrap',
	rowGap: token('space.150', '12px'),
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageDescription = styled2.p({
	margin: `0 0 ${token('space.250', '20px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageContainer = styled2.div({
	margin: '0px auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 98}px`,
});

const dividerStyles = xcss({
	border: 0.5,
	borderStyle: 'solid',
	borderColor: 'color.border',
	position: 'absolute',
	// not tokenize due to no space.negative.500, maximum 400.
	right: '-40px',
	left: '-40px',
});

const navRefreshPageContainerStyle = xcss({
	margin: '0 auto',
	paddingTop: 'space.250',
	maxWidth: '1072px',
	minWidth: '656px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageBodyContainer = styled2.div({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.500', '40px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageRowContainer = styled2.div({
	display: 'flex',
	gap: token('space.500', '40px'),
});

const leftColumnNavRefreshStyles = xcss({
	flex: 7,
	minWidth: `${LEFT_COLUMN_NAV_REFRESH_MIN_WIDTH}px`,
});

const rightColumnNavRefreshStyles = xcss({
	flex: 3,
	minWidth: `${RIGHT_COLUMN_NAV_REFRESH_MIN_WIDTH}px`,
});

const leftColumnStyles = xcss({
	flex: 3,
	minWidth: `${LEFT_COLUMN_MIN_WIDTH}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RightColumn = styled2.div({
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinksContainer = styled2.div({
	display: 'flex',
	flexDirection: 'row',
});

const screenReaderOnlyStyles = xcss({
	position: 'absolute',
	width: '1px',
	height: '1px',
	margin: 'space.negative.025',
	padding: 'space.0',
	overflow: 'hidden',
	clip: 'rect(0, 0, 0, 0)',
	border: 0,
});

const overflowStyle = xcss({
	overflowX: 'auto',
	overflowY: 'hidden',
	paddingLeft: 'space.050',
});

const breadCrumbsWrapper = xcss({
	marginTop: 'space.250',
	marginBottom: 'space.negative.150',
});

const minWidthStyles = xcss({
	minWidth: '656px',
});

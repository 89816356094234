import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	affectedObjectsHeaderNonFinal: {
		id: 'servicedesk-cmdb-async-actions-results-modal.common.affected-objects-header',
		defaultMessage: 'Affected object',
		description: 'Affected object table column header',
	},
	attributesEditedHeaderNonFinal: {
		id: 'servicedesk-cmdb-async-actions-results-modal.common.attribute-edited-header',
		defaultMessage: 'Attribute edited',
		description: 'Attribute edited table column header',
	},
	actionPerformedHeaderNonFinal: {
		id: 'servicedesk-cmdb-async-actions-results-modal.common.action-performed-header',
		defaultMessage: 'Action performed',
		description: 'Action performed table column header',
	},
	errorsHeaderNonFinal: {
		id: 'servicedesk-cmdb-async-actions-results-modal.common.errors-header',
		defaultMessage: 'Error',
		description: 'Error table header',
	},
});
